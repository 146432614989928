<template>
    <v-card class="pa-6 pb-2">
        Agregar Producto a la Venta {{addDetail}}
        <v-row class="ma-0 mt-4">
            <v-col ols="12" sm ="4" md="2" class="py-0 my-0">
                <v-text-field type=number v-model="sale_item.quantity" label="Cantidad"></v-text-field><!--:disabled="yanohay(item.quantity, item.item, k)" -->
            </v-col>
            <v-col ols="12" sm ="8" md="6" class="py-0 my-0">
                <v-autocomplete v-model="sale_item.cellular_plan_id" :items="devicesList" item-value="id" item-text="name" label="Producto"> 
                    <template v-slot:item="{item, attrs, on}">
                        <v-list-item v-on="on" v-bind="attrs">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.name}} | {{(item.price*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template> 
                </v-autocomplete>
            </v-col>
            <v-col cols="12" sm ="8" md="4" class="py-0 my-0">
                <v-text-field disabled v-model="sale_item.price" prefix="$" suffix="c/u" label="Precio"></v-text-field>
            </v-col>
        </v-row>
        <v-card-actions>
            <v-spacer class="hidden-md-and-down"></v-spacer>
            <v-btn color="blue darken-1" text @click="closeButton">
                Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="save"  :loading="gris" :disabled="gris">
                Guardar
            </v-btn>
        </v-card-actions>

        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-card>
</template>

<script>
import axios from "axios"
export default {
    props:{
        addDetail:Number
    },
    data: () => ({
        gris: false,
        snackbar: {
            show: false,
            message: null,
            color: null
        },
        sale_item:{
            quantity:1,
            cellular_plan_id:'',
            value:'',
            price:'',
            editItem:false,
            createItem:false,
        }
    }),
    watch: {
        cellular_plan_id:{
            handler(){
                this.sale_item.price = this.devicesList.filter(item=>item.id == this.sale_item.cellular_plan_id).map(item=>item.price)[0]
            },deep:true
        },
    },
    computed:{
        cellular_plan_id(){
            return this.sale_item.cellular_plan_id
        },
        devicesList(){
            return this.$store.state.item.items
        },
    },
    methods:{
        categories(categories){
            var respuesta = ''
            for(var i=0; i<categories.length; i++){
                if(i>0){
                    respuesta = respuesta + ', ' + this.categoryName(categories[i])
                }
                else{
                    respuesta = this.categoryName(categories[i])
                }
            }
            return respuesta
        },
        categoryName(id){
            return this.$store.state.category.categories.filter(category => category.id == id).map(category => category.name)[0];
        },
        inventoryCalc(id){
            var quantities = this.$store.state.inventory.inventories.filter(inventory=>inventory.item_id == id)
            var sum = 0
            for(var i=0; i<quantities.length; i++){
                if(quantities[i].type == 'Salida Producción' || quantities[i].type == 'Salida Venta'){
                    sum = sum - quantities[i].quantity
                }
                else if(quantities[i].type == 'Entrada Producción' || quantities[i].type == 'Entrada Compra' || quantities[i].type == 'Ajuste Manual' || quantities[i].type == 'Creación de Producto' || quantities[i].type == 'Ajuste Inventario Físico'){
                    sum = sum + quantities[i].quantity
                }
            }
            return sum
        },
        close () {
            const sale_item = this.sale_item
            this.sale_item = {
                quantity:1,
                item_id:'',
                price:'',
                quotation_id:'',
                editItem:false,
                createItem:false,
            }
            this.gris = false
            this.$nextTick(() => {
                this.$emit("closeDialogAddDetail", sale_item);
            })
        },
        closeButton () {
            this.sale_item = {
                quantity:1,
                item_id:'',
                price:'',
                quotation_id:''
            }
            this.gris = false
            this.$nextTick(() => {
                this.$emit("closeDialogAddDetail", 'cancelar');
            })
        },
        save(){
            this.sale_item.quotation_id = this.addDetail
            this.gris = true
            this.$nextTick(() => {
                axios.post(process.env.VUE_APP_BACKEND + "api/v1/sale_item_details", this.sale_item).then(response => {
                    this.sale_item.id = response
                    this.close()
                }).catch(error => {
                    this.snackbar = {
                        message: error.response.data.message,
                        color: 'error',
                        show: true
                    }
                    this.gris = false
                    this.close()
                })
            })
        },
    }
}
</script>

<style>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"pb-0 pt-1"},[_c('v-icon',{staticClass:"mr-4",staticStyle:{"background":"#e7e8e9","padding":"10px!important","border-radius":"50%"}},[_vm._v("mdi-file-document-outline")]),_c('span',[_vm._v("Nueva Nota")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.permissions('assignNotes'))?_c('v-autocomplete',{attrs:{"rules":[function (v) { return !!v || 'Campo requerido'; }],"clearable":"","items":_vm.usersLists,"label":"Vendedor","item-text":"name","item-value":"id"},model:{value:(_vm.note.to_user_id),callback:function ($$v) {_vm.$set(_vm.note, "to_user_id", $$v)},expression:"note.to_user_id"}},[_c('template',{staticClass:"pa-2",slot:"no-data"},[_vm._v("No existen usuarios relacionados.")])],2):(_vm.note.to_user_id=_vm.currentUser.id)?_c('v-autocomplete',{attrs:{"disabled":"","items":_vm.usersLists,"label":"Vendedor","item-text":"name","item-value":"id"},model:{value:(_vm.note.to_user_id),callback:function ($$v) {_vm.$set(_vm.note, "to_user_id", $$v)},expression:"note.to_user_id"}}):_vm._e()],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pb-0"},[_c('v-container',[_c('v-row',[_c('v-autocomplete',{attrs:{"items":_vm.clientsList,"loading":_vm.isLoadingClients,"search-input":_vm.searchClients,"hide-no-data":"","item-value":"id","item-text":"name","label":"Cliente","placeholder":"Escribe para buscar","attach":""},on:{"update:searchInput":function($event){_vm.searchClients=$event},"update:search-input":function($event){_vm.searchClients=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[(item.name!=null)?_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]):(item.razon_social!=null)?_c('v-list-item-title',[_vm._v(" "+_vm._s(item.razon_social)+" ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.note.client_id),callback:function ($$v) {_vm.$set(_vm.note, "client_id", $$v)},expression:"note.client_id"}})],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-textarea',{attrs:{"label":"Descripcion"},model:{value:(_vm.note.comment),callback:function ($$v) {_vm.$set(_vm.note, "comment", $$v)},expression:"note.comment"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","loading":_vm.gris,"disabled":_vm.gris || !_vm.valid,"text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.createContactDialog),callback:function ($$v) {_vm.createContactDialog=$$v},expression:"createContactDialog"}},[_c('createContact',{attrs:{"company":_vm.note.client_id},on:{"closeCreateDialogContact":_vm.closeCreateDialogContact}})],1),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#e25200","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.createCompanyDialog),callback:function ($$v) {_vm.createCompanyDialog=$$v},expression:"createCompanyDialog"}},[_c('createCompany',{on:{"closeCreateDialogCompany":_vm.closeCreateDialogCompany}})],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }